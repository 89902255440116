import { ChakraProvider, ColorModeScript, extendTheme } from "@chakra-ui/react";
// import { Global } from '@emotion/react';
import { type ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
  cssVarPrefix: "tk",
};

export const theme = extendTheme({
  config,

  colors: {
    brand: {
      primaryBrand: "#00A0F5",
      secondaryDark: "#003C7D",
      secondaryMedium: "#0055BE",
      secondaryBright: "#0078DC",
      secondaryLight: "#50AFE1",
      secondaryNormal: "#73C3F0",
      orange: "#FFB400",
      purple: "#A51482",
      red: "#E60050",
      yellow: "#FAE100",
      green: "#9BC832",
      turquoise: "#0F968C",
      black: "#000000",
      grayDark: "#4B5564",
      grayMedium: "#78879B",
      grayLight: "#D9DEE8",
      gray: "#EEF0F2",
      white: "#FFFFFF",
      inactiveGray: "#C0C3C9",
      hoverLightBlue: "#D5EDFB",
      warning: "#D49704",
      alertWarning: "#FFEDC3",
    },
  },

  fonts: {
    body: "tktype,Calibri,Arial,Helvetica,sans-serif",
    heading: "tktype,Calibri,Arial,Helvetica,sans-serif",
    mono: "Menlo, monospace",
  },

  semanticTokens: {
    colors: {
      white: {
        default: "#FFFFFF",
        _dark: "#000000",
      },
      black: {
        default: "#000000",
        _dark: "#FFFFFF",
      },
    },
  },
});

type themPropType = {
  children: JSX.Element | JSX.Element[];
};

export default function ChakraThemeProvider({ children }: themPropType) {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      {children}
    </ChakraProvider>
  );
}
