import { Box, Stack, Text, useColorModeValue } from "@chakra-ui/react";

export default function Footer() {
  return (
    <Box
      position={"fixed"}
      bottom={0}
      width={"100%"}
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Stack
        py={4}
        direction={{ base: "column", md: "row" }}
        justify={{ base: "center", md: "center" }}
        align={{ base: "center", md: "center" }}
      >
        <Text fontSize={12}>Thyssenkrupp © 2022</Text>
      </Stack>
    </Box>
  );
}
