import { useState, useEffect } from "react";
import { cache } from "../Auth";

function getSavedValue(key: string, initialValue: any, location: any) {
  const data: any = location.getItem(key);
  const savedValue = JSON.parse(data);
  if (savedValue) return savedValue;
  if (initialValue instanceof Function) return initialValue();
  return initialValue;
}

export default function useLocalStorage(key: string, initialValue: any) {
  const location = cache.cacheLocation === "localStorage" ? localStorage : sessionStorage;
  const [value, setValue] = useState(() => {
    return getSavedValue(key, initialValue, location);
  });
  useEffect(() => {
    location.setItem(key, JSON.stringify(value));
  }, [value, key, location]);
  return [value, setValue];
}
