import { cache, system } from ".";
import { PublicClientApplication } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_signin",
    forgotPassword: "<Forgot Password Name - in the form of B2C_1_xxx>",
    editProfile: "<Edit Profile Name - in the form of B2C_1_xxx>",
  },
  authorities: {
    signUpSignIn: {
      authority: "https://tkpooc.b2clogin.com/tkpooc.onmicrosoft.com/B2C_1_signin",
    },
    forgotPassword: {
      authority:
        "https://tkpooc.b2clogin.com/tkpooc.onmicrosoft.com/<Forgot Password Name - in the form of B2C_1_xxx>",
    },
    editProfile: {
      authority:
        "https://tkpooc.b2clogin.com/tkpooc.onmicrosoft.com/<Edit Profile Name - in the form of B2C_1_xxx>",
    },
  },
  authorityDomain: "tkpooc.b2clogin.com",
};

const Config = {
  auth: {
    clientId: "b2ee3f23-9d6c-4925-adbe-971a96b8f76e",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.REACT_APP_BASE_URL,
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache,
  system,
};
export const b2cInstance = new PublicClientApplication(Config);
