import {
  //  LogLevel,
  IPublicClientApplication,
  AccountInfo,
} from "@azure/msal-browser";
export const loginRequest = {
  AD: {
    scopes: [
      "api://0ff989f7-57b6-422a-ace8-f8efa5aa1c69/Authorization.Read"
    ],
  },
  B2C: {
    scopes: [
      "https://tkpooc.onmicrosoft.com/464ebbbc-e931-448c-80f9-08a793dd5c10/Authorization.Read",
    ],
  },
};

export const system = {
  loggerOptions: {
    // loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
    //   if (containsPii) {
    //     return;
    //   }
    //   switch (level) {
    //     case LogLevel.Error:
    //       console.error(message);
    //       return;
    //     case LogLevel.Info:
    //       console.info(message);
    //       return;
    //     case LogLevel.Verbose:
    //       console.debug(message);
    //       return;
    //     case LogLevel.Warning:
    //       console.warn(message);
    //       return;
    //     default:
    //       return;
    //   }
    // },
  },
};

export const cache = {
  cacheLocation: "sessionStorage",
  storeAuthStateInCookie: false,
};

export function handleRedirectLogin(Instance: IPublicClientApplication) {
  Instance.handleRedirectPromise()
    .then((a) => {
      if (a?.account) {
        Instance.setActiveAccount(a?.account);
      }
    })
    .catch((b) => {
      console.log("erro");
      console.log(b);
    });
}

export async function AcquireToken(
  Instance: IPublicClientApplication,
  lr: any,
  account: AccountInfo
) {
  try {
    const response = await Instance.acquireTokenSilent({
      ...lr,
      account,
    });
    return response;
  } catch (e) {
    console.error(e);
    return e;
  }
}
