import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

export let reactPlugin = new ReactPlugin();
export let appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "26f6bba7-e576-4f1e-8dca-57974e1a7f5f",
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();
