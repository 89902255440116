import { useMsal } from "@azure/msal-react";
import { useQuery } from "@tanstack/react-query";
import { Link, Outlet, useOutletContext } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Heading,
  Flex,
  Text,
  VStack,
} from "@chakra-ui/react";
import { VscChevronRight } from "react-icons/vsc";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

import { AcquireToken, loginRequest } from "../../Auth";
import Layout from "../../Component/NavBar/NavBar";
import { reactPlugin } from "../../Auth/appInsight";
import { BrandSpinner } from "../../Component";

type PropType = {
  type: keyof typeof loginRequest;
};

export function Home({ type }: PropType) {
  const { instance, accounts } = useMsal();
  const { data: acc, isLoading } = useQuery(
    ["accessToken"],
    () => AcquireToken(instance, loginRequest[type], accounts[0]),
    {
      enabled: accounts.length ? true : false,
      select: (res: any) => ({ ...res.account, token: res.accessToken }),
    }
  );

  if (isLoading)
    return (
      <VStack height={"100vh"} alignItems={"center"} justifyContent={"center"}>
        <BrandSpinner />
      </VStack>
    );
  else
    return (
      <Layout>
        <ProfileContent type={type} acc={acc} />
      </Layout>
    );
}

export default withAITracking(reactPlugin, Home);

type ProfileContentPropType = {
  type: keyof typeof loginRequest;
  acc: any;
};

function ProfileContent({ type, acc: graphData }: ProfileContentPropType) {
  return (
    <>
      <Flex justifyContent={"space-between"} alignItems={"center"} pb={3}>
        <Box>
          <Heading as={"h6"} fontSize={"lg"} pb={1}>
            SAP Data
          </Heading>
          <Breadcrumb fontSize={"xs"} separator={<VscChevronRight color="gray.500" />}>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="#">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink isCurrentPage={true}>SAP Data</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Flex>
      <Outlet context={{ graphData, type }} />
    </>
  );
}


export function Profile() {
  const { graphData } = useOutletContext<any>();
  return (
    <>
      {graphData && (
        <>
          <Box p={2} borderRadius={10} backgroundColor={"white"} fontSize={"sm"}>
            <Text>
              <strong>User Name : </strong> {graphData?.name}
            </Text>
            <p>
              <strong>Email : </strong> {graphData?.username}
            </p>
            {graphData.idTokenClaims.oid ? (
              <p>
                <strong>Id : </strong> {graphData?.idTokenClaims?.oid}
              </p>
            ) : null}
            {graphData.token ? (
              <p>
                <strong>Token : </strong> {graphData?.token}{" "}
              </p>
            ) : null}
          </Box>
        </>
      )}
    </>
  );
}
