export function BrandSpinner() {
  return (
    <svg
      width="60px"
      height="15px"
      viewBox="0 0 46 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>loading_trio1</title>
      <desc>Created with Sketch.</desc>
      <g id="animated-svg" stroke="none" strokeWidth="0" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-34.000000, -19.000000)" fill="#00A0F5">
          <g id="loading_trio1" transform="translate(35.000000, 20.000000)">
            <circle id="Oval3" className="Oval3" cx="4.5" cy="4.5" r="4.5" />
            <circle id="Oval2" className="Oval2" cx="22.5" cy="4.5" r="4.5" />
            <circle id="Oval1" className="Oval1" cx="39.5" cy="4.5" r="4.5" />
          </g>
        </g>
      </g>
    </svg>
  );
}
