import { lazy } from "react";
import { useRoutes } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { AcquireToken, loginRequest } from "../Auth";
import { Profile } from "../Pages/Home/Home";
import { azureFetcher } from "../api/fetcher";
import { FetchMenu } from "../api/menu";
import { useQuery } from "@tanstack/react-query";
import Tabs from "../Component/ui/Tabs";

const NoMatch = lazy(() => import("../Pages/NoMatch/NoMatch"));
const Home = lazy(() => import("../Pages/Home/Home"));


type PropType = {
  type: keyof typeof loginRequest;
};

export function UseRoute({ type }: PropType) {
  const { instance, accounts } = useMsal();
  const { data: accessToken } = useQuery(
    ["accessToken"],
    () => AcquireToken(instance, loginRequest[type], accounts[0]),
    {
      enabled: accounts.length ? true : false,
      onSuccess: (res: any) => {
        console.log(res);
        azureFetcher.defaults.headers.common.Authorization = `Bearer ${res.accessToken}`;
        azureFetcher.defaults.headers.common['Authorization-Source'] = type;
      },
    }
  );
  const { data = [] } = useQuery(["menu"], () => FetchMenu(accounts[0].username), {
    enabled: !!accessToken,
    select: (d) => {
      return d?.application?.applicationRoles?.applicationModules
        ?.map((item: { applicationEntity: [] }) =>
          item.applicationEntity.map((e: { entityName: string , urlPath : string }) => {
            return {
              path: `${e.urlPath}`,
              element: <p>{e.entityName}</p>,
            };
          })
        )
        .flat();
    },
  });

  const element = useRoutes([
    {
      path: "/",
      element: <Home type={type} />,
      children: [
        { index: true, element: <Tabs /> },
        { path: "Profile", element: <Profile /> },
        ...data,
      ],
    },
    {
      path: "*",
      element: <NoMatch />,
    },
  ]);
  return element;
}
