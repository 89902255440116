import axios from "axios";

export const azureFetcher = axios.create({
  baseURL: "https://apim-tkcommon-dev.azure-api.net",
  headers: {
    Accept: "*/*",
    "Content-Type": "text/plain; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    "Ocp-Apim-Subscription-Key": "d40500b8e41040b487aefac040f07c0f",
    "Ocp-Apim-Trace": true
  },
});
