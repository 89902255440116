import { Tabs as Tabss, Tab, TabList,  TabPanels } from "@chakra-ui/react";
import { CustomTable } from "./Table";
import axios from "axios";
import { useEffect, useState } from "react";

let tabs = [
  {
    tab: "Bom",
    file: "file.material_bom_full.json",
  },
  {
    tab: "Detail Parts",
    file: "file.material_detailParts_full.json",
  },
  {
    tab: "Process Parts",
    file: "file.material_processParts_full.json",
  },
  {
    tab: "SubProcess Parts",
    file: "file.material_subProcessParts_full.json",
  },
  {
    tab: "Error Bom",
    file: "errors.bom.json",
  },
  {
    tab: "Error Detail",
    file: "errors.detail_part.json",
  },
];
export default function Tabs() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [state, setState]: any = useState({});
  const [loading, setLoading] = useState(true);

  const getApi = async (fileName: string) => {
    try {
      const data = await axios.get(`
        https://stortkcommondev001.blob.core.windows.net/sap-integration-data/${fileName}?sp=r&st=2022-09-13T05:16:12Z&se=2024-09-13T13:16:12Z&spr=https&sv=2021-06-08&sr=c&sig=qJj86EkImpf%2BvgDbRtSXnh0YgwL146GNLn9roWOieLo%3D
        `);
      setLoading(false);
      setState({ ...state, [selectedTab]: data.data });
    } catch (err) {
      console.log(err);
      return err;
    }
  };
  useEffect(() => {
    if (!state[selectedTab]?.length) {
      
      getApi(tabs[selectedTab].file);
    }
    else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [selectedTab]);
  
  return (
    <div>
      <Tabss onChange={(index) => {setSelectedTab(index);setLoading(true); }}>
        <TabList>
          {tabs.map((e) => (
            <Tab style={{ textTransform: "capitalize" }}>{e.tab}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {loading ? (
            <p>loading</p>
          ) : (
            <CustomTable title={tabs[selectedTab].tab} data={state[selectedTab]} />
          )}
        </TabPanels>
      </Tabss>
    </div>
  );
}
