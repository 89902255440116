import { azureFetcher } from "./fetcher";

export async function FetchSiteCode(userName: string) {
  try {
    const response = await azureFetcher.get(`audit/api/v1/sitecodemaster?userName=${userName}`);
    return response.data;
  } catch (er) {
    console.log(er);
    return er;
  }
}
