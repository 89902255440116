import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { FiBell, FiChevronDown } from "react-icons/fi";
import { useMsal } from "@azure/msal-react";
import logo from "../../assets/logo.png";
import Footer from "./Footer";
import { Select } from "chakra-react-select";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { FetchSiteCode } from "../../api/sideCodes";
import { FetchMenu } from "../../api/menu";
import NavBarMenu from "./NavBarMenu";
import { useAppContext } from "../..";


type SidebarWithHeaderType = {
  children: JSX.Element | JSX.Element[];
};
export default function SidebarWithHeader({ children }: SidebarWithHeaderType) {

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <MobileNav  />
      <Box >
        <Box p={3}>
          <Box marginBottom={"50px"}>{children}</Box>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}




const MobileNav = () => {
  const { instance, accounts } = useMsal();
  const { setIsADuser } = useAppContext();
  const { data = [] } = useQuery(["siteCodeMaster"], () => FetchSiteCode(accounts[0].username), {
    select: (res) => res.map((e: any) => ({ value: e.siteCodeName, label: e.siteCodeName })),
  });
  const { data: userRole } = useQuery(["menu"], () => FetchMenu(accounts[0].username));
  // const navigate = useNavigate();
  const handleLogout = () => {
    setIsADuser(null);
    instance.logoutRedirect();
  };
  return (
    <>
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue("white", "gray.900")}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        justifyContent={{ base: "space-between", md: "space-between" }}
      >
        <HStack>
          <img src={logo} alt={"logo"} style={{ marginRight: 30 }} />
          <Box display={{ base: "none", md: "block" }} bg={"white"} width={"200px"}>
            <Select
              name="colors"
              options={data}
              placeholder="Search"
              size="sm"
              chakraStyles={{
                dropdownIndicator: (provided) => ({
                  ...provided,

                  cursor: "inherit",
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
              }}
            />
          </Box>
        </HStack>
        <Flex>
          <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />
          <Menu>
            <MenuButton
              display={"flex"}
              alignItems={"center"}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
              paddingLeft={3}
              borderLeft={"2px solid #DEDEDE"}
            >
              <HStack>
                <Avatar size={"sm"} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  my={2}
                >
                  <Text fontSize="md">{accounts.length && accounts[0].name}</Text>
                  <Text fontSize="12px">{userRole?.application?.roleName}</Text>
                </VStack>
                <Box display={{ md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem as={Link} to={"profile"}>
                Profile
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={handleLogout}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Box bg={useColorModeValue("white", "gray.900")}><NavBarMenu /></Box>
    </>
  );
};
