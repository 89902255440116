import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Text } from "@chakra-ui/react";

export function CustomTable({ title, data }: { title: string; data: any }) {
  return (
    <TableContainer bg={"white"} color="brand.primaryBrand" fontWeight="600">
      <Text textTransform={"capitalize"} fontSize="16" p="4">
        {title}
      </Text>
      <Table
        variant="striped"
        color="black"
        borderTop={"4px solid red"}
        borderTopColor={"brand.primaryBrand"}
        colorScheme="blackAlpha"
      >
        <Thead>
          <Tr>
            {Object.keys(data[0]).map((e, key) => (
              <Th key={key} fontSize="14" textTransform="capitalize" fontWeight="700">
                {e}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((body:any, key:number) => (
            <Tr key={key}>
              {Object.values(body).map((i, index) => (
                <Td key={index}>{`${i}`}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
