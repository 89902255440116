import { azureFetcher } from "./fetcher";

export async function FetchMenu(userName: string) {
  try {
    const response = await azureFetcher.get(`/api/v1/role`);
    return response.data;
  } catch (er) {
    console.log(er);
    return er;
  }
}
