import {
  Box,
  Link as Links,
  Icon,
  Text,
  VStack,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Stack,
  Flex,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { FetchMenu } from "../../api/menu";
import { FiTrendingUp, FiCompass, FiStar } from "react-icons/fi";
import { BrandSpinner } from "../ui/BrandSpinner";
import { useMsal } from "@azure/msal-react";
import { BsChevronDown } from "react-icons/bs";
import { useState } from "react";
const icon = [FiCompass, FiTrendingUp, FiStar, FiCompass, FiTrendingUp, FiStar];

export default function Menu() {
  const { accounts } = useMsal();
  const [link, setLink]: any = useState(null);
  const { data, isLoading, error } = useQuery(["menu"], () => FetchMenu(accounts[0].username));
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  if (isLoading)
    return (
      <VStack px={3} pt={3}>
        <BrandSpinner />
      </VStack>
    );
  if (error) return <Text>Something went wrong please contact admin</Text>;

  return (
    <Flex px={5} direction="row">
      {data?.application?.applicationRoles?.applicationModules?.map((item: any, index: number) => (
        <Popover key={index} trigger={"hover"} placement={"bottom-start"}>
          <PopoverTrigger>
            <Flex>
              <Links
                px={2}
                py={4}
                ml={2}
                onMouseOver={() => setLink(index)}
                onMouseOut={() => setLink(null)}
                position="relative"
                as={Link}
                to={item.url ?? "#"}
                fontSize={"sm"}
                display="flex"
                alignItems="center"
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                <Icon w={5} h={5} as={icon[index / 2]} />
                <Text pl={2}>{item.moduleName}</Text>
                {link === index && (
                  <>
                    <Icon pl={2} w={5} h={5} as={BsChevronDown} />
                    <Box
                      height={"4px"}
                      bg="brand.primaryBrand"
                      position="absolute"
                      bottom="0"
                      left="0"
                      width="100%"
                      borderRadius="5px 5px 0 0"
                    />
                  </>
                )}
              </Links>
              {item?.applicationEntity?.length && (
                <PopoverContent
                  border={0}
                  boxShadow={"xl"}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={"xl"}
                  minW={"sm"}
                >
                  <Stack>
                    {item?.applicationEntity?.map((i: any, key: any) => (
                      <DesktopSubNav key={key} entityName={i.entityName} href={"#"} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Flex>
          </PopoverTrigger>
        </Popover>
      ))}
    </Flex>
  );
}

const DesktopSubNav = ({ href, entityName }: any) => {
  return (
    <Links
      as={Link}
      to={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text fontSize={"sm"}>{entityName}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
        </Flex>
      </Stack>
    </Links>
  );
};
