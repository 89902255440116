import React, { lazy, StrictMode, useMemo } from "react";
import ReactDOM from "react-dom/client";
import "./web.config";

import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./index.css";
import { adInstance } from "./Auth/authConfigAAD";
import { b2cInstance } from "./Auth/authConfigB2C";
import ChakraThemeProvider from "./Utils/theme";
import useLocalStorage from "./hooks/useLocalStorage";
import { UseRoute } from "./Utils/Route";
const Login = lazy(() => import("./Pages/Login/Login"));

const queryClient = new QueryClient();

export type appContextStoreType = {
  isADuser: "AD" | "B2C";
  setIsADuser: (a: string | null) => void;
};
export const AppContext = React.createContext<any>({});

export const useAppContext = () => {
  const data: appContextStoreType = React.useContext(AppContext);
  return data;
};

function Index() {
  const [isADuser, setIsADuser] = useLocalStorage("userType", null);
  const value = useMemo(() => ({ isADuser, setIsADuser }), [isADuser, setIsADuser]);
  let instance = useMemo(() => isADuser === "AD" ? adInstance : b2cInstance, [isADuser]);
  let accountArr = instance.getAllAccounts().length;
  
  return (
    <StrictMode>
      <ChakraThemeProvider>
        <AppContext.Provider value={value}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <React.Suspense fallback={<p>Loading</p>}> 
                <MsalProvider instance={instance}>
                  {isADuser === null && accountArr === 0 ? <Login isADuser={isADuser} setIsADuser={setIsADuser} /> : <UseRoute type={isADuser === "AD" ? "AD" : "B2C"} />}
                </MsalProvider>
              </React.Suspense>
            </BrowserRouter>
          </QueryClientProvider>
        </AppContext.Provider>
      </ChakraThemeProvider>
    </StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<Index />);
